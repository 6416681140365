body {
    margin: 0;
    padding: 0;
    background-color: black;
    color: white;
}

#myphoto {
    border-radius: 50%;
}

.profilePic {
    /* transform: scale3d(1.5, 1.5, 1.5); */
}

.container {
    margin-top: 60px;
}

.myInfo {
    text-align: left;
    display: inline-block;
}

button,
img {
    cursor: pointer;
    margin-right: 20px;
}


/* dark mode */
.dark {
    color: rgb(0, 0, 0);
    background-color: rgb(255, 255, 255);
}

.dark #btnDark {
    background-color: rgb(0, 0, 0);
}

#btnDark {
    top: 0;
    right: 0;
    position: fixed;
    margin-right: 0px;
    padding: 15px;
    background-color: rgb(255, 255, 255);
}

.proj_img {
    border: 5px ridge;
    border-radius: 20px;
    margin-top: 10px;
    border-color: red green yellow blue;
}

.proj_img:hover {
    opacity: 0.4;
}

.profilePic {
    /* width: 50%; */
    margin-left: 10vw;
}



/* desk view only */
@media screen and (min-width: 900px) {
    .container {
        flex-direction: row-reverse;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 60px;
        width: 70%;
    }

   
    .projects {
        width: 30%;
    }
    .con_ten {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

}

/* mobile view only */
@media screen and (max-width: 899px) {
    .con_ten {
        flex-flow: column wrap;
        display: flex;
        flex-direction: column-reverse;
    }
    .projects{
        margin: 40px 0;
    }
    /* #myphoto-mbl{
        transform: scale3d(2.0, 2.0, 2.0);
        border-radius: 50%;
        margin: 160px 15px 40px 10px ;
    }
    .container{
        flex-direction: column;
        align-items: center;

    }
    .myInfo {
        margin-top: 70px;
    } */
}